/* You can add global styles to this file, and also import other style files */
// Standard CSS normalize, comment out if not required or using a different module
@import "minireset.css/minireset";

/* See: https://www.infragistics.com/products/ignite-ui-angular/angular/components/themes.html */
@import "@infragistics/igniteui-angular/lib/core/styles/themes/index";

$primary: #128af3 !default;
$secondary: #128af3 !default;

$app-palette: palette($primary, $secondary);

/* autoprefixer grid: on */

@include core();
@include theme($app-palette);

html,
body {
  height: 100%;
}
.cursor {
  &__pointer {
    cursor: pointer;
  }
}
// ---------- size ----------
$site-w-min: 320px;
$site-contents-min: 700px;
$site-w-pc: 1280px;
$site-w-sp: 375px;
$breakPointWide: 1022px;
$breakPoint: 768px;

// ---------- color ----------
$color-gray0: #ffffff;
$color-gray100: #f8f8f8;
$color-gray200: #f0f0f0;
$color-gray300: #dddddd;
$color-gray400: #cccccc;
$color-gray700: #737b80;
$color-gray900: #32393d;
$color-blue: #1387f3;
$color-blue-light: #edf3f9;
$color-blue-light2: #f6fbff;
$color-blue-light3: #e4f0fc;
$color-positive: #19c179;
$color-negative: #ed3d45;
$color-negative-light: #fff8f9;
$color-caution: #ffcd33;
$color-hover-blue: #2fa8ff;
.igx-overlay__wrapper,
.igx-overlay__wrapper--modal,
.igx-overlay__wrapper--flex,
.igx-overlay__wrapper--flex-container {
  z-index: 11000;
}
.igx-overlay__wrapper,
.igx-overlay__wrapper--modal,
.igx-overlay__wrapper--flex,
.igx-overlay__wrapper--flex-container {
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}
.igx-overlay__content,
.igx-overlay__content--modal,
.igx-overlay__content--elastic,
.igx-overlay__content--relative {
  //IgxDialogの表示位置が画面トップになってしまうので、いったんコメント化
  // top: 0;
  position: absolute;
}
//  AngularMaterialインストール時に設定されたが、見た目が変わるのでコメント化 
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// モーダル表示時のバックカラーをAngularバージョンアップ前に合わせる
.igx-overlay__wrapper--modal, .igx-overlay__content--modal {
  --background-color: rgba(0, 0, 0, 0.38);
}
// モーダル表示時のタイトルカラーをAngularバージョンアップ前に合わせる
.igx-dialog__window-content {
  color: rgba(0, 0, 0, 0.6);
}